.has-dropdown {
  position: relative;

  .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1000;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    transform: translateX(-50%);

    ul {
      @apply bg-zz-green text-white;
      padding: 10px;
      margin: 0;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);

      li { // 0630315581 de bilt
        margin: 0 !important;
        a {
          color: white !important;
        }
      }
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #134024;
    }
  }

  &:hover {
    .dropdown {
      display: flex;
    }
  }
}