header {
  a {
    text-decoration: none;
  }

  margin-bottom: 80px;

  &.not-logged-in-header {
    margin-top: 80px;
    margin-bottom: 100px;

    .inner {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &.main-header {

    position: sticky;
    top: 0;
    z-index: 100;

    @apply bg-white;
    @apply shadow-lg;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @apply py-6;

      .left {
        img {
          max-height: 40px;
        }
      }

      .right {
        .hamburger {
          @apply block lg:hidden;

          > span {
            width: 50px;
            height: 5px;
            @apply bg-zz-green;
            display: block;
            margin: 8px auto;
            transition: all 0.3s ease-in-out;
          }

          cursor: pointer;

          &[data-menu-trigger="open"] {
            span:nth-child(2) {
              opacity: 0;
            }

            span:nth-child(1) {
              transform: translateY(13px) rotate(45deg);
            }

            span:nth-child(3) {
              transform: translateY(-13px) rotate(-45deg);
            }
          }
        }

        .menu-items {
          margin: 0;
          padding: 0;

          align-items: center;
          font-size: 16px;
          line-height: 1.2;
          @apply text-zz-green hidden lg:flex;


          li {
            margin-left: 30px;

            &.questionmark {
              font-size: 20px;

              a {
                padding: 3px 7px;
                border-radius: 50%;
                border: 3px solid;
                @apply bg-zz-green text-white transition-all border-zz-green;

                &:hover {
                  @apply bg-white text-zz-green;
                }
              }
            }

            &.profile {

              font-size: 20px;
            }

            a {
              @apply text-zz-green hover:text-zz-green hover:underline;
            }
          }
        }
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  display: none;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  padding-top: 150px;

  &[data-menu="open"] {
    display: block;
  }

  ul {
    li {
      a {
        text-decoration: none;
        font-weight: bold;
        font-size: 30px;
        line-height: 1.2;
        @apply text-zz-green;
      }

      margin-bottom: 15px;
    }
  }
}