.template-profile {
  .content {

    ul {
      @apply grid grid-cols-1 md:grid-cols-2 gap-6;
      margin-bottom: 25px;

      li {
        font-size: 15px;
        line-height: 18px;
        color: #5D5D5D;

        &.title {
          @apply text-zz-green font-bold col-span-2;
        }

        &.full-width {
          @apply col-span-2;
        }
      }
    }
  }
}