[modal] {
  $modalSpacing: var(--modal-spacing, 2rem);
  $modalSpacing_large: var(--modal-spacing-large, 2rem);

  &[modal-state="invalid"], &:not([modal-state]) {
    display: none;
  }

  &[modal-state]:not([modal-state="invalid"]):not([modal-state=""]) {
    display: flex;
  }

  transition: all 0.3s ease-in-out;

  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: var(--modal-backdrop-opacity, 0);
  }

  &[modal-state="opened"] {
    display: flex;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;

    --modal-backdrop-opacity: 1;

    .modal-content {
      transform: translate(0,0);
      opacity: 1;
    }
  }

  .modal-content {
    transform: translate(-50vw, 0);
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 100%;
    max-height: 100vh;
    overflow: auto;
    margin: $modalSpacing_large;
    max-width: calc(min(100vw, var(--modal-max-width, 620px)));
    background-color: var(--modal-background-color, #FFFFFF);
    box-shadow: var(--modal-box-shadow, 0 0 1rem rgba(0, 0, 0, 0.2));
    position: relative;

    &__header {
      color: white;
      @apply bg-zz-green;

      * {
        color: white;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: $modalSpacing;
    }


    &__close {
      padding: 1rem;
      cursor: pointer;
      font-size: 1.5rem;
    }

    &__inner {
      &__text {
        padding: $modalSpacing;
      }

      &__bullet-points {
        padding: $modalSpacing;
        @apply grid grid-cols-1 gap-4;

        li {
          font-size: 1.5rem;
          i {
            @apply text-zz-green;
            font-size: 1.2em;
          }

          @apply text-gray-700;
        }
      }

      &__images {
        padding: $modalSpacing;
        @apply grid grid-cols-4 gap-4;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

  }
}

[modal-trigger], [modal-close], [modal-open] {
  cursor: pointer;
}
