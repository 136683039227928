.content-block-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .content-block {
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);

    width: 100%;
    max-width: 1007px;

    &.small {
      max-width: 760px;
    }

    &.medium {
      max-width: 960px;
    }

    &.large {
      @apply max-w-lg;
    }
  }
}

.content-block-container .content-block {
  .content-block-inner {
    width: 100%;
    @apply grid grid-cols-1 lg:grid-cols-3;

    .inner {
      @apply px-6 py-20 lg:py-40;
      //padding: 100px 15px;
      width: 100%;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;

      @apply bg-zz-green text-white;

      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1, h2, h3, h4, h5, h6 {
          @apply text-white mb-0 lg:mb-12;
          text-align: center;
        }
      }
    }

    .right {
      @apply lg:col-span-2 bg-white;
      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > form, > .content {
          max-width: 480px;
          width: 100%;
        }

        h1, h2, h3, h4, h5, h6 {
          text-align: center;
          margin-bottom: 25px;
        }
      }
    }
  }
}