footer {
  display: block;
  margin-top: 50px;
}

.help-button {
  padding: 10px 15px;

  position: fixed;
  font-size: 14px;
  line-height: 1.2;
  bottom: 5px;
  right: 5px;
  border-radius: 50000px;
  text-decoration: none !important;
  border: 3px solid;

  @apply bg-zz-green text-white transition-all border-zz-green;

  i {
    margin-right: 7px;
  }

  &:hover {
    @apply bg-white text-zz-green;
  }
}