@import 'variables';


body {
  font-size: 16px;
  line-height: 1.5;
  color: #333;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  > main {
    flex: 1;
  }

  @apply bg-zz-light;
}

h1,h2,h3,h4 {
  @apply text-zz-green;
  font-size: 40px;
  line-height: 1.2;
  font-weight: bold;
}


.seperator, .separator {
  width: 100%;
  height: 2px;
  background-color: #eaeaea;
  margin-top: 20px;
  margin-bottom: 20px;
}

a {
  @apply text-zz-green;
  text-decoration: underline;
}

@import 'components';

