.single-order {

  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .content {
      @apply bg-white shadow-xl;
      max-width: 620px;
      width: 100%;

      .content-header {
        @apply bg-zz-green text-white p-4;
        text-align: center;

        .content-title {
          @apply text-white;
        }
      }

      .content-body {
        @apply p-16;

        ul {
          margin-bottom: 35px;
          @apply grid grid-cols-2;

          li {
            margin-bottom: 15px;

            &.title {
              @apply col-span-2;

              font-weight: bold;
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}