$success_badge_color: #4caf50;
$warning_badge_color: #ff9800;
$error_badge_color: #f44336;
$primary_badge_color: #2196f3;

.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid transparent;

  &.badge-primary {
    color: $primary_badge_color;
    background-color: rgba($primary_badge_color, 0.2);
    border-color: $primary_badge_color;
  }
}