.archive_order_item {
  @apply  bg-white shadow-xl text-black;

  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 100%;
    @apply p-8;

    .title {
      @apply text-black;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .date {
      color: #525252;
      font-size: 14px;
      display: block;
      line-height: 16px;
      margin-bottom: 15px;
    }
    .badge {
      display: block;
      width: fit-content;
      text-align: center;
      min-width: 110px;
      margin-bottom: 15px;
    }
  }
}