$alert-danger-bg: #f2dede;
$alert-danger-border: #ebccd1;
$alert-danger-text: #a94442;

$alert-success-bg: #dff0d8;
$alert-success-border: #d0e9c6;
$alert-success-text: #3c763d;

$alert-info-bg: #d9edf7;
$alert-info-border: #bce8f1;
$alert-info-text: #31708f;

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;

  &.alert-danger {
    color: $alert-danger-text;
    background-color: $alert-danger-bg;
    border-color: $alert-danger-border;
  }

  &.alert-success {
    color: $alert-success-text;
    background-color: $alert-success-bg;
    border-color: $alert-success-border;
  }

  &.alert-info {
    color: $alert-info-text;
    background-color: $alert-info-bg;
    border-color: $alert-info-border;
  }
}