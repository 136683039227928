.template-login {
  .login-block, .register-block {
    width: 100%;
    @apply grid grid-cols-3;

    .inner {
      padding: 100px 15px;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;

      @apply bg-zz-green text-white;

      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          text-align: center;
          margin-bottom: 30px;
        }
      }
    }

    .right {
      @apply col-span-2 bg-white text-zz-green;

      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          margin-bottom: 25px;
        }

      }
    }
  }
}