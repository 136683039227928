
.archive-orders {
  .order-container {
    @apply grid lg:grid-cols-3 gap-16;

    .order {

    }
  }
}
