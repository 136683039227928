.btn {
  display: inline-block;
  padding: 15px 15px;
  font-size: 15px;
  line-height: 1;
  border-radius: 5px;

  border: 1px solid;

  min-width: 130px;
  text-align: center;

  text-decoration: none !important;

  @apply transition;

  &-light {
    @apply bg-zz-light text-zz-green border-zz-light;

    @apply hover:bg-zz-green hover:text-zz-light;
  }

  &-green {
    @apply bg-zz-green text-white border-zz-green;

    &:not(:disabled) {
      @apply hover:bg-zz-light hover:text-zz-green;
    }

    &[disabled] {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}