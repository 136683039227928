
.smart-form {
  width: 100%;
  @apply grid grid-cols-2 gap-x-12;

  label {
    display: inline-flex;

    > * {
      margin-left: 5px;
    }

    .tooltip {
      position: relative;

      .tooltip-trigger {
        @apply bg-zz-green text-white;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .tooltip-content {
        z-index: 11;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
        display: none;
        @apply bg-white text-zz-green p-4 shadow;
      }

      &:hover {
        .tooltip-content {
          display: block;
        }
      }
    }
  }

  .input-container {
    width: 100%;
    margin-top: 25px;
    position: relative;
    font-size: 16px;

    input[type="text"], input[type="password"], input[type="number"], input[type="email"], select, textarea {
      width: 100%;
      padding: 8px 5px;
      outline: none !important;
      border: 1px solid;
      border-radius: 3px;

      @apply bg-zz-gray transition border-zz-gray;

      &:focus, &:active {
        @apply bg-white text-zz-green placeholder-zz-green border-zz-green;
      }

      &:focus ~ label, &:active ~ label, &.input-container__input--filled ~ label, &.always-show-label ~ label {
        top: 0;
        font-size: 10px;
        transform: translateY(0);
      }

      & ~ label {
        pointer-events: none;
        transition: all .2s;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
      }
    }


    &.checkbox {
      label {
        font-size: 12px;
        line-height: 1.1;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
            @apply text-zz-green;
          }
        }

        @apply text-zz-green;
      }

      input[type="checkbox"] {
        width: 15px;
        height: 15px;
        visibility: hidden;
        position: relative;


        & + label:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 15px;
          height: 15px;
          border: 2px solid;
          visibility: visible;
          @apply border-zz-gray-dark;

          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          content: "";

          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 12px;
        }

        &:checked + label:before {
          @apply bg-zz-green text-white border-zz-green;
          content: "\f00c";
        }
      }


    }


    &.input-item-number {
      label {
        @apply text-zz-green;
        font-size: 15px;
        line-height: 18px;
      }

      &.input-number-has-controls {
        .input-number-group {
          display: flex;
          justify-content: space-between;
          align-items: center;

          border-radius: 5px;
          overflow: hidden;

          button {
            transition: all .2s;
            @apply text-zz-green p-4 bg-zz-gray;

            &:hover {
              @apply bg-opacity-50;
            }
          }
        }
      }

      input {

      }
    }
  }

  .full-width {
    @apply col-span-2;
  }

  .forgot-password, .link {
    text-align: right;
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
    font-size: 13px;
    text-decoration: underline;

    @apply col-span-2;

    @apply italic text-zz-gray-dark hover:no-underline hover:text-zz-gray-dark;
  }

  .text-part {
    font-size: 13px;
    line-height: 1.2;
  }

  .input-group {
    @apply grid grid-cols-2 gap-x-12;
  }

  .buttons {
    @apply col-span-2;
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.between {
      @apply justify-between;
    }
  }

  .data-preview {
    @apply bg-zz-gray col-span-2 p-4;

    .title {
      font-size: 24px;
      font-weight: 700;
      @apply text-black;
      text-align: left !important;
      margin-bottom: 10px !important;
    }

    ul {
      @apply grid grid-cols-1 md:grid-cols-2 gap-x-12;
    }
  }

  .hidden {
    display: none;
  }
}

.gravity-form {
  input:not([type="submit"]), textarea, select {
    width: 100%;
    padding: 8px 5px;
    outline: none !important;
    border: 1px solid;
    border-radius: 3px;

    @apply bg-zz-gray transition border-zz-gray;
  }

  input[type="submit"] {
    display: inline-block;
    padding: 15px 15px;
    font-size: 15px;
    line-height: 1;
    border-radius: 5px;

    border: 1px solid;

    min-width: 130px;
    text-align: center;

    text-decoration: none !important;

    @apply transition;

    @apply bg-zz-green text-white border-zz-green;

    &:not(:disabled) {
      @apply hover:bg-zz-light hover:text-zz-green;
    }

    &[disabled] {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}
