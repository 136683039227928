.address-selector-container {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;

  select {
    width: 100%;
    padding: 8px 5px;
    outline: none !important;
    border: 1px solid;
    border-radius: 3px;

    @apply bg-zz-gray transition border-zz-gray;

    &:focus, &:active {
      @apply bg-white text-zz-green placeholder-zz-green border-zz-green;
    }
  }

  label {
    display: none;
  }
}